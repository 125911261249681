/* You can add global styles to this file, and also import other style files */

/* COMMON */

$primary-color: #000;
$primary-color-hover: lighten($primary-color, 25%);
$primary-color-focus: lighten($primary-color, 75%);
$primary-color-active: lighten($primary-color, 35%);
$focus-ring: lighten($primary-color, 90%);

:root {
  //--primary-color:#6366F1;
  --primary-color: #{$primary-color};
  --primary-color-hover: #{$primary-color-hover};
  --primary-color-focus: #{$primary-color-focus};
  --primary-color-active: #{$primary-color-active};
  --focus-ring: #{$focus-ring};
  --text-color: #000;
  --text-color-secondary: #828d9d;
}

body {
  font-family: var(--font-family);
  width: 100%;
  height: 100%;
  margin: 0px;
}

/* LOGO */
.cabinet-logo {

  width: 100%;

  svg {
    fill: #fff;
    width: 170px;
  }
}

.p-button,
.p-inputtext,
.p-dropdown,
.p-multiselect,
.p-checkbox .p-checkbox-box {
  border-radius: 0px;
}

/* SPINNER */
.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: $primary-color;
  stroke-width: 1;
}

@keyframes custom-progress-spinner-dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}

/* CABINET PANEL MENU */

.p-panelmenu {

  &.cabinet {
    width: 100%;
    border: none;

    .p-panelmenu-panel {
    }

    .p-panelmenu-content {
      border: none;
    }

    .p-panelmenu-header {
      > a {
        border: none;
        background: none;
        padding: 1rem;

        .p-menuitem-icon {
          font-size: 1.25rem;
          width: 32px;
        }

        &.p-menuitem-link-active {
          color: $primary-color;
        }
      }
    }

    .p-menuitem-text {
      flex-grow: 1;
      font-size: 1rem;
    }

    .p-panelmenu-content {

      .p-menuitem {
        .p-menuitem-link {

          padding: 0.75rem 1.25rem 0.75rem 3.5rem;

          .p-menuitem-text {
            color: var(--text-color-secondary);
          }

          &:not(.p-disabled):hover {
            background: none;
          }

          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
          }

          &.p-menuitem-link-active {

            .p-menuitem-text {
              color: $primary-color;
            }

          }
        }
      }

    }

    .p-panelmenu-header {

      > a {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }

      &.p-highlight {

        > a {
          color: var(--text-color-secondary);
          transition-property: color;
          transition-duration: .2s;
          transition-timing-function: ease-in-out;

          &.p-menuitem-link-active {
            color: $primary-color;
          }
        }
      }
    }

    .p-panelmenu-header > a .p-panelmenu-icon {
      order: 1;
    }

    .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
      background: none;
      color: $primary-color;
    }

    .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
      background: none;
      color: $primary-color;
    }
  }
}

/* CABINET MENU */

.p-menu {

  &.cabinet {

    width: 100%;
    border: none;

    .p-submenu-header {
      color: $primary-color;
    }

    .p-menuitem-link {

      .p-menuitem-text {
        transition-property: color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        color: var(--text-color-secondary);
      }

      .p-menuitem-icon {
        color: var(--primary-color);
      }

      &:not(.p-disabled):hover {

        background: none;

        .p-menuitem-icon {
          color: var(--primary-color);
        }
      }

      &:focus {
        box-shadow: none;
      }

      &.p-menuitem-link-active {
        .p-menuitem-text {
          color: var(--text-color);
        }
      }
    }

  }

}

/* TOOLBAR */

.p-toolbar {

  &.cabinet {
    padding: 0px;
    background: none;
    border: none;

    .p-button, .p-button.p-button-outlined {
      border: none;
      color: #fff;
      font-size: 1.25rem;

      &:enabled:hover {
        color:#fff;
        border: none;
        background: #2e2e2e;
      }

      &:focus {
        box-shadow: none;
      }
    }

  }

}

/* SCROLLBAR */

.p-scrollpanel {

  .p-scrollpanel-bar {
    background: var(--gray-600);
    border: 0 none;
    opacity: 1;

  }
}

.p-scrollpanel-bar-y {
  width: 3px !important;
}

/* GLOBAL MENU */

.p-menu {

  .p-menuitem-link {
    .p-menuitem-icon {
      color: var(--primary-color);
    }

    &:not(.p-disabled):hover {
      .p-menuitem-icon {
        color: var(--primary-color);
      }
    }
  }

}
