/* rubik-300 - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-300italic - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-italic - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700italic - latin_cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/rubik-v21-latin_cyrillic-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
